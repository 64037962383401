
<template>

  <div class="desktop">

    <Taskbar />

    <DesktopIcons />

    <router-view>

      <template v-slot="{ Component }">

        <keep-alive :max="5">

          <component :is="Component" :key="$route.fullPath" />

        </keep-alive>

      </template>

    </router-view>

  </div>

</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");
</style>

<script>
//import Taskbar from "/src/components/Taskbar.vue";

import Taskbar from "/src/components/taskbar_local.vue";
import DesktopIcons from "/src/components/DesktopIcons.vue";
import "./assets/style.css";
export default {
  name: "App",
  components: {
    Taskbar: Taskbar,
    DesktopIcons: DesktopIcons
  },
  data() {
    return {
      startMenuOpen: false
    };
  },
  methods: {
    toggleStartMenu(currentState) {
      this.startMenuOpen = currentState;
    }
  }
};
</script>

