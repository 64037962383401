import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/resume.vue")
  },
  {
    path: "/resume",
    name: "Resume",
    component: () => import("../components/resume.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../components/Contact.vue")
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../components/Project2.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",
    component: () => import("../components/PageNotFound.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function(_to, _from, savedPosition) {
    return savedPosition ? savedPosition : window.scrollTo(0, 0);
  }
});

export default router;
