User
<template>

  <div class="taskbar">

    <button class="taskbar-button" @click="openWindow('posWindow')">
       Resume
    </button>

    <button class="taskbar-button" @click="openWindow('openContactWindow')">
       Contact
    </button>

  </div>

</template>

<script>
import { eventBus } from "@/main";
import { ref } from "vue";

export default {
  data() {
    return {
      activeWindow: null
    };
  },
  methods: {
    async openWindow(windowName) {
      const isResumeRoute = this.$route.path.includes("/resume");
      const isContactRoute = this.$route.path.includes("/contact");
      if (windowName === "posWindow") {
        if (!isResumeRoute) {
          this.$router.push(`/resume`);
        }
        this.activeWindow = windowName;
      }
      if (windowName === "openContactWindow") {
        if (!isContactRoute) {
          this.$router.push(`/contact`);
        }
        this.activeWindow = windowName;
      }
    }
  },
  watch: {
    "$store.state.openPosWindow": {
      immediate: true,
      handler(value) {
        if (value) {
          this.openWindow("posWindow");
          this.$store.setWindowState("openPosWindow", false); // Reset the state
        }
      }
    },
    "$route.fullPath": {
      immediate: true,
      handler(path) {
        if (path === "/resume") {
          console.log(path);
          this.openWindow("posWindow");
        }
        if (path === "/contact") {
          console.log(path);
          this.openWindow("openContactWindow");
        }
      }
    }
  }
};
</script>

