<template>
  <div class="desktop-icon" @click="openPosWindow">
    <font-awesome-icon :icon="['fas', 'file-alt']" class="desktop-icon-fa" />
    <span>Resume</span>
  </div>
</template>

<script>
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { eventBus } from "@/main";
export default {
  components: {
    FontAwesomeIcon,
  },
  methods: {
    openPosWindow() {
      this.$store.setWindowState("openPosWindow",true);
    },
  },
};
</script>

<style scoped>
/* Add your custom styling for the desktop icon */
</style>
