<template>

  <div class="desktop-icons">

    <ResumeIcon />

    <ContactIcon />

  </div>

</template>

<script>
import ResumeIcon from "./ResumeIcon.vue";
import ContactIcon from "./ContactIcon.vue";
import ProjectIcon from "./ProjectIcon.vue";

export default {
  components: {
    ResumeIcon,
    ContactIcon,
    ProjectIcon
  }
};
</script>

<style scoped>

</style>

