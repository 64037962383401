import { reactive, readonly, ref } from "vue";

const state = reactive({
  openPosWindow: false,
  openContactWindow: false,
  openProjectsWindow: false,
});

const mutations = {
  setWindowState(windowType, value) {
    state[windowType] = value;
  },
};

export const useStore = () => {
  return {
    state: readonly(state),
    setWindowState: mutations.setWindowState,
  };
};
