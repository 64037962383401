<template>

    <div class="desktop-icon" @click="openProjects">
  
      <font-awesome-icon
        :icon="['fa', 'diagram-project']"
        class="desktop-icon-fa"
      />
  
      <span>Projects</span>
  
    </div>
  
  </template>
  
  <script>
  export default {
    methods: {
      openProjects() {
        if (!this.$route.path.includes("/projects")) {
          this.$router.push(`/projects`);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your custom styling for the desktop icon */
  </style>
  
  