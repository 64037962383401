<template>

  <div class="desktop-icon" @click="openContactWindow">

    <font-awesome-icon
      :icon="['fas', 'address-book']"
      class="desktop-icon-fa"
    />

    <span>Contact</span>

  </div>

</template>

<script>
import { eventBus } from "@/main";
export default {
  methods: {
    openContactWindow() {
      if (!this.$route.path.includes("/contact")) {
        this.$router.push(`/contact`);
      }
    }
  }
};
</script>

<style scoped>
/* Add your custom styling for the desktop icon */
</style>

